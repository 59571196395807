import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { dateAndTimeToReadString } from "../utils/format"
import Line from "../components/line"
import { MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants"
import styled from "styled-components"

class Blog extends React.Component {
  render() {
    const { data } = this.props

    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const { show_quote, quote, quote_author } = data.markdownRemark.frontmatter

    const quoteC = (
      <blockquote>
        {quote}
        <footer>
          <cite>{quote_author}</cite>
        </footer>
      </blockquote>
    )

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={siteTitle}
          noTitleTemplate
          ogTitle="Vasco Samouco — Ideias à volta do futebol e com vida própria"
        />
        <Hero>
          Ideias à volta do futebol e com vida própria
          <br />
          gestão | liderança | formação | educação
        </Hero>
        {show_quote ? quoteC : null}
        <div style={{ margin: "20px 0 40px" }}>
          {posts.map(({ node }, index) => {
            const title = node.frontmatter.title || node.fields.slug
            const description = node.frontmatter.description

            return (
              <div key={node.fields.slug}>
                <h2
                  style={{
                    marginBottom: 0,
                    lineHeight: 0.9,
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h2>
                <small>{dateAndTimeToReadString(node)}</small>
                {description ? (
                  <p
                    style={{ marginTop: "10px" }}
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                ) : null}
                {index + 1 === posts.length ? null : <Line />}
              </div>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default Blog

const Hero = styled.h4`
  display: block;
  margin-bottom: 20px;
  font-family: Montserrat, sans-serif;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 40px;

  ${MOBILE_MEDIA_QUERY} {
    font-size: 14px;
    text-align: left;
    margin: 20px 0;
  }
`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: "/" } }) {
      frontmatter {
        show_quote
        quote
        quote_author
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { type: { ne: "page" }, draft: { ne: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY.MM.DD")
            title
            description
          }
        }
      }
    }
  }
`
