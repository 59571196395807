const months = {
  "01": "Janeiro",
  "02": "Fevereiro",
  "03": "Março",
  "04": "Abril",
  "05": "Maio",
  "06": "Junho",
  "07": "Julho",
  "08": "Agosto",
  "09": "Setembro",
  "10": "Outubro",
  "11": "Novembro",
  "12": "Dezembro",
}

export function formatTimeToRead(minutes) {
  if (minutes === 1) {
    return `${minutes} min de leitura`
  }

  return `${minutes} mins de leitura`
}

export function formatDate(dateString) {
  const [year, month, day] = dateString.split(".")

  return `${day} de ${months[month]}, ${year}`
}

export function dateAndTimeToReadString(node) {
  return `${formatDate(node.frontmatter.date)} - ${formatTimeToRead(
    node.timeToRead
  )}`
}
